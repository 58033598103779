<script>
import {
  ArrowUpIcon,
  UserIcon,
  MailIcon,
  BookIcon,
  MessageCircleIcon,
} from "vue-feather-icons";

import emailjs from "emailjs-com";

export default {
  data() {
    return {
      emailSent: false,
      email: "",
      firstName: "",
      lastName: "",
    };
  },
  components: {
    UserIcon,
    ArrowUpIcon,
    MailIcon,
    BookIcon,
    MessageCircleIcon,
  },

  methods: {
    sendEmail: function(e) {
      const self = this;
      emailjs
        .sendForm(
          "promitechsiteservice",
          "promitech_template1",
          e.target,
          "user_zdLNeAVAIdiN8zUpbenIT"
        )
        .then(
          (result) => {
            console.log("SUCCESS!", result.status, result.text);
            self.emailSent = true;
            setTimeout(() => {
              self.emailSent = false;
              self.email = "";
              self.firstName = "";
              self.lastName = "";
            }, 5000);
          },
          (error) => {
            console.log("FAILED...", error);
          }
        );
    },
  },
};
</script>

<template>
  <div>
    <div class="row align-items-center">
      <div class=" pt-2 pt-sm-0 order-2 order-md-1">
        <div class="card shadow rounded border-0">
          <div class="card-body py-5" v-if="!emailSent">
            <h4 class="card-title">Contact Us Today!</h4>
            <div class="custom-form mt-4">
              <div id="message"></div>
              <form
                name="contact-form"
                id="contact-form"
                @submit.prevent="sendEmail"
              >
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group position-relative">
                      <label
                        >Your Name <span class="text-danger">*</span></label
                      >
                      <user-icon class="fea icon-sm icons"></user-icon>
                      <input
                        name="name"
                        id="name"
                        type="text"
                        class="form-control pl-5"
                        placeholder="Enter your Name"
                        v-model="firstName"
                      />
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-md-6">
                    <div class="form-group position-relative">
                      <label
                        >Your Email <span class="text-danger">*</span></label
                      >
                      <mail-icon class="fea icon-sm icons"></mail-icon>
                      <input
                        name="email"
                        id="email"
                        type="email"
                        class="form-control pl-5"
                        placeholder="Your email :"
                        v-model="email"
                      />
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-md-12">
                    <div class="form-group position-relative">
                      <label>Subject</label>
                      <book-icon class="fea icon-sm icons"></book-icon>
                      <input
                        name="subject"
                        id="subject"
                        type="text"
                        class="form-control pl-5"
                        placeholder="Subject"
                      />
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-md-12">
                    <div class="form-group position-relative">
                      <label>Comments</label>
                      <message-circle-icon
                        class="fea icon-sm icons"
                      ></message-circle-icon>
                      <textarea
                        name="comments"
                        id="comments"
                        rows="4"
                        class="form-control pl-5"
                        placeholder="Your Message :"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <!--end row-->
                <div class="row">
                  <div class="col-sm-12 text-center">
                    <input
                      type="submit"
                      id="submit"
                      name="send"
                      class="submitBnt btn btn-primary btn-block"
                      value="Send Message"
                    />
                    <div id="simple-msg"></div>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </form>
              <!--end form-->
            </div>
            <!--end custom-form-->
          </div>

          <div v-if="emailSent" class="card-body py-5">
            <h4 class="card-title text-center">All Set!</h4>
            <div class="icon text-center p-4 ">
              <img
                src="/images/icon/mail.svg"
                class="avatar avatar-small text-center"
              />

              <p class="lead h2  p-2 text-center">
                Thank you for contacting us, we will get back to you very soon.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
